import { Injectable } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  async init() {
  }

  async set(key: string, value: any) {
    return await Preferences.set({  key: key, value: value});
  }

  async get(key: string) {
    return (await Preferences.get({ key: key })).value
  }

  async remove(key: string) {
    return await Preferences.remove({ key: key });
  }
}
