import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class NgxChangePasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
