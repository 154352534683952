import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApplicationConfig, BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { ActionSheetController, IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NbThemeModule, NbLayoutModule, NbSidebarModule, NbButtonModule, NbCalendarKitModule, NbCalendarModule, NbCalendarRangeModule, NbCardModule, NbDialogModule, NbIconModule, NbInputModule, NbListModule, NbProgressBarModule, NbSelectModule, NbSpinnerModule, NbStepperModule, NbTabsetModule, NbToggleModule, NbUserModule, NbMenuModule } from '@nebular/theme';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ThemeModule } from './modules/theme.module';
// import { NbPasswordAuthStrategy, NbAuthModule } from '@nebular/auth';
import { NgxEchartsModule } from 'ngx-echarts';
import { DragulaModule } from 'ng2-dragula';
import { IonicGestureConfig } from './services/utils/IonicGestureConfig'
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from './@auth/auth.module';
import { GraphQLModule } from './graphql.module';
import { NotificationService } from './services/notification/notification.service';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { provideRemoteConfig,getRemoteConfig } from '@angular/fire/remote-config';
import { getAuth, provideAuth } from '@angular/fire/auth';


@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NbThemeModule.forRoot({ name: 'newdefault' }),
        NbLayoutModule,
        NbEvaIconsModule,
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        ThemeModule.forRoot(),
        AuthModule.forRoot(),
        DragulaModule.forRoot(),
        GraphQLModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideAnalytics(() => getAnalytics()),
      //  providePerformance(() => getPerformance()),
      //  provideRemoteConfig(() => getRemoteConfig()),
       
    ],
    providers: [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      {provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig},
      ScreenTrackingService,UserTrackingService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
