<p class="sub-title mb-4">Please set a new password</p>

<div [formGroup]="changePasswordForm" aria-labelledby="title">

  <div class="form-control-group">
    <label for="input-password">Password:</label>
    <input nbInput autofocus fullWidth id="input-password" formControlName="password" type="password"
      [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" class="first" placeholder="Password">
    <ngx-validation-message label="Password" [showMinLength]="password?.hasError('minlength') && password.touched"
      [showMaxLength]="password?.hasError('maxlength') && password.touched"
      [showRequired]="password?.errors?.required && password.touched" [minLength]="minLength" [maxLength]="maxLength">
    </ngx-validation-message>
  </div>

  <div class="form-control-group">
    <label for="input-re-password">Confirm Password:</label>
    <input nbInput fullWidth id="input-re-password" formControlName="passwordConfirmation" type="password"
      [status]="passwordConfirmation.dirty ? (passwordConfirmation.invalid || password.value != passwordConfirmation.value  ? 'danger' : 'success') : ''"
      placeholder="Password">
    <ngx-validation-message label="Confirm Password"
      [showMinLength]="passwordConfirmation?.hasError('minlength') && passwordConfirmation.touched"
      [showMaxLength]="passwordConfirmation?.hasError('maxlength') && passwordConfirmation.touched"
      [showRequired]="passwordConfirmation?.errors?.required && passwordConfirmation.touched" [minLength]="minLength"
      [maxLength]="maxLength"></ngx-validation-message>
    <p class="error-message caption status-danger"
      *ngIf="password.value != passwordConfirmation.value && passwordConfirmation.touched">
      Password and confirm password does not match!
    </p>
  </div>

  <button nbButton status="success" fullWidth
    [disabled]="submitted || !changePasswordForm.valid || password.value != passwordConfirmation.value"
    [class.btn-pulse]="submitted" (click)="changePassword()">
    Change password
  </button>
</div>