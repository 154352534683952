import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { reduce, isArray, has } from "lodash";
import { environment } from '../../../environments/environment'
import { orderBy } from "lodash";
import * as dayjs from "dayjs";

declare var google: any;
declare var window:any;

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  prodApiUrl = "https://guildmates-api.onrender.com/api";
  devApiUrl = "http://localhost:1337/api";
  prod = true;

  constructor(private http: HttpClient) {}

  getApi() {
    return this.prod ? this.prodApiUrl : this.devApiUrl;
  }

  makeRequest(
    url,
    type: RequestType = RequestType.get,
    data = undefined,
    options = undefined
  ): Promise<any> {
    return new Promise((resolve, reject) => {

      let request;
      if (type== RequestType.get) request = this.http.get(`${this.getApi()}/${url.trim()}`);
      else if (options && type == RequestType.post) { request = this.http['post'](`${this.getApi()}/${url.trim()}`, data, options);}
      else request = this.http[type](`${this.getApi()}/${url.trim()}`, data);

      request.subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          console.log("There was an Error", error);
          reject(error);
        }
      );
    });
  }

  makeGetRequest(url) {
    return this.makeRequest(url, RequestType.get);
  }

  makePostRequest(url, data = null, options = null) {
    return this.makeRequest(url, RequestType.post, {data: data}, options);
  }

  makePutRequest(url, data = null) {
    return this.makeRequest(url, RequestType.put, {data: data});
  }

  makePatchRequest(url, data = null) {
    return this.makeRequest(url, RequestType.patch, {data: data});
  }

  makeDeleteRequest(url) {
    return this.makeRequest(url, RequestType.delete);
  }
}

export function getSecoundsBetweenDates(from, to){
  from = new Date(from);
  to = new Date(to);
  return Number(((to-from)/1000).toFixed(0))
}

export function getDaysFromToday(date){
  return dayjs(new Date()).diff(date, 'day',false)
}

export function orderbyNearestDate(list, dateProperty){
  return orderBy(list, [(item) => {
    return dayjs(item[dateProperty]).format('YYYY-MM-DD')
 }], ['desc'])
}

export enum RequestType {
  get = "get",
  post = "post",
  put = "put",
  patch = "patch",
  delete = "delete",
}
