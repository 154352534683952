import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbProgressBarModule,
  NbTabsetModule,
  NbUserModule,
  NbIconModule,
  NbSelectModule,
  NbListModule,
  NbSpinnerModule,
  NbCalendarKitModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbDialogModule,
  NbToggleModule,
  NbInputModule,
  NbFormFieldModule,
  NbAccordionModule,
  NbTagModule,
  NbChatModule,
  NbDatepickerModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbActionsModule,
  NbButtonGroupModule,

} from '@nebular/theme';

import { ExploreContainerComponent  } from '../components/explore-container/explore-container.component';
import { HeaderComponent } from '../components/header/header.component';
import { IonicModule } from '@ionic/angular';
import { NgxEchartsModule } from 'ngx-echarts';

// import { NewItemDirective } from './new-item.directive';
// import { OrdersPipe } from './orders.pipe';

const MODULES = [
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbProgressBarModule,
  NbTabsetModule,
  NbUserModule,
  NbIconModule,
  NbSelectModule,
  NbListModule,
  NbSpinnerModule,
  NbCalendarKitModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbDialogModule,
  NbToggleModule,
  NbInputModule,
  NbFormFieldModule,
  NbUserModule,
  NbAccordionModule,
  NbTagModule,
  NbChatModule,
  NbDatepickerModule.forRoot(),
  NbCheckboxModule,
  NbActionsModule,
  NgxEchartsModule.forRoot({
    echarts: () => import('echarts'),
  }),
  NbButtonGroupModule
]

const COMPONENTS = [
]

const PIPES = [

]

@NgModule({
 imports: [ CommonModule, ...MODULES ],
 declarations: [ ...COMPONENTS, ...PIPES],
 exports:      [ CommonModule, ...PIPES, ...COMPONENTS, ...MODULES ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
    };
  }
}
